import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import queryString from 'querystring';
import Services from 'components/Services/Services';
import tvMountingImg from 'components/Services/tv-mounting.png';

export interface AcuityService {
  serviceImage: string;
  serviceType: string;
  serviceTypeId: number;
  serviceUrl: string;
  sortOrder: number;
  serviceCategory?: string;
}

export interface AcuityAppointment {
  date: string;
  datetime: string;
  canceled: boolean;
  id: number;
  type: string;
  confirmationPage: string;
  time: string;
  endTime: string;
}

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  userType: UserType;
  building: Building;
}

export interface Building {
  serviceTypes: ServiceType[];
}

export interface ServiceType {
  serviceType: number;
  permissionAccessLevel: 'NoAccess' | 'ReadOnly' | 'FullAccess';
}

export enum UserType {
  NonResident = 'Non-Resident',
}

const API_URL = process.env.REACT_APP_BASE_API_URL;

const ServicesContainer = ({ token, ...rest }: Props) => {
  const [services, setServices] = useState<AcuityService[]>([
    {
      serviceImage: tvMountingImg,
      serviceType: 'TV Mounting',
      serviceTypeId: 1,
      serviceCategory: 'HelloTech',
      serviceUrl: 'https://www.hellotech.com/landings/livly',
      sortOrder: 0,
    },
  ]);
  const [appointments, setAppointments] = useState<AcuityAppointment[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const leaseId = queryString.parse(
    window.location.search
  ).property_unit_lease_id;

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchUser = useCallback(
    async () =>
      await axios(
        `${API_URL}/livly/users/me${leaseId ? `?leaseId=${leaseId}` : ''}`,
        headers
      ),
    [headers, leaseId]
  );

  const fetchServices = useCallback(
    async () =>
      await axios(
        `${process.env.REACT_APP_BASE_API_URL}/v2/users/me/services${
          leaseId ? `?leaseId=${leaseId}` : ''
        }`,
        headers
      ),
    [headers, leaseId]
  );

  const fetchAppointments = useCallback(
    async () => await axios(`${API_URL}/v2/users/me/appointments`, headers),
    [headers]
  );

  useEffect(() => {
    const doFetch = async () => {
      try {
        const [userPromise, servicesPromise, appointmentsPromise] =
          await Promise.all([
            fetchUser(),
            fetchServices(),
            fetchAppointments(),
          ]);
        const currentUser = userPromise.data.Data;
        const { userId } = currentUser;

        //@ts-ignore
        window.analytics.identify(userId);
        //@ts-ignore
        window.analytics.track('Load Services Page');

        const allServices = servicesPromise.data.Data;
        const activeServices = allServices.filter(
          ({ serviceUrl }: AcuityService) => serviceUrl
        );

        setUser(currentUser);
        setServices([...services, ...activeServices]);
        setAppointments(appointmentsPromise.data.Data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError('Error loading services');
      }
    };
    doFetch();

    //eslint-disable-next-line
  }, []);

  const onStartBooking = (service: AcuityService) => {
    //@ts-ignore
    window.analytics.track('Start Service Booking', {
      'Service Type': service.serviceType,
    });
  };

  return (
    <Services
      {...rest}
      user={user}
      services={services}
      appointments={appointments}
      loading={loading}
      error={error}
      token={token}
      onStartBooking={onStartBooking}
    />
  );
};

interface OwnProps {
  token: string;
}

type Props = OwnProps & RouteComponentProps;

export default ServicesContainer;
