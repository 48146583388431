import React, { useState } from 'react';
import {
  Typography,
  Box,
  makeStyles,
  Theme,
  Dialog,
  Slide,
  DialogContent,
  DialogActions,
  Button,
  ButtonBase,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Skeleton } from '@material-ui/lab';
import { Icon, colors } from '@livly/styleguide-components';
import { RouteComponentProps } from 'react-router-dom';
import qs from 'qs';

import { AcuityService, AcuityAppointment, User } from 'pages/Services';
import Appointments from './Appointments';

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  }
);

const Services = (props: Props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRestrictedAccessModalOpen, setIsRestrictedAccessModalOpen] =
    useState(false);
  const { services, appointments, loading, error, onStartBooking } = props;

  const { user, history } = props;
  const servicesServiceType =
    user &&
    user.building.serviceTypes.find((service) => service.serviceType === 7);

  const hasServicesFullAccess =
    servicesServiceType &&
    servicesServiceType.permissionAccessLevel === 'FullAccess';

  history.listen((location) => {
    const { isModalOpen } = qs.parse(location.search);
    setModalOpen(isModalOpen === 'true');
  });

  const onToggleModal = (isModalOpen: boolean) => {
    const {
      location: { search },
      history,
    } = props;
    const parse = qs.parse(search.replace('?', ''));
    const withModal = { ...parse, isModalOpen };
    const query = qs.stringify(withModal);

    history.push(`/service/list?${query}`);
  };

  if (error) return <Typography>{error}</Typography>;

  const onClickService = (service: AcuityService) => {
    if (!hasServicesFullAccess) {
      setIsRestrictedAccessModalOpen(true);
      return;
    }

    onStartBooking(service);
    window.location.href = service.serviceUrl;
  };

  return (
    <>
      <Box pt={2} p={2.75}>
        <Box mb={2}>
          <Typography className={classes.subheader}>
            On-demand services verified for convenience and quality.
          </Typography>
        </Box>
        <Box my={3}>
          {!loading && hasServicesFullAccess && (
            <ButtonBase
              className={classes.bookingsButton}
              onClick={() => onToggleModal(true)}
            >
              <Icon name="calendar" size={30} color={colors.livly()} />
              <Box pl={2} textAlign="left">
                <Typography variant="h5" className={classes.h5}>
                  My Service Bookings
                </Typography>
                <Typography variant="body1" style={{ color: '#F9877E' }}>
                  View bookings
                </Typography>
              </Box>
            </ButtonBase>
          )}
        </Box>

        <ul>
          {(loading ? Array.from(new Array(3)) : services).map(
            (service: AcuityService, index: number) => (
              <li
                key={index}
                style={{ listStyle: 'none', marginBottom: '2em' }}
              >
                {service ? (
                  <div
                    className={classes.serviceLink}
                    onClick={() => onClickService(service)}
                  >
                    <img
                      style={{ width: '100%' }}
                      src={service.serviceImage}
                      alt={service.serviceType}
                    />
                    <Typography variant="body2" gutterBottom>
                      {service.serviceCategory
                        ? service.serviceCategory
                        : 'Livly Services'}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={`${classes.serviceSubheader} ${classes.h5}`}
                    >
                      {service.serviceType}
                    </Typography>
                  </div>
                ) : (
                  <>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={218}
                    ></Skeleton>
                    <Skeleton width="50%"></Skeleton>
                    <Skeleton width="40%"></Skeleton>
                  </>
                )}
              </li>
            )
          )}
        </ul>
      </Box>
      <Dialog
        fullScreen
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => onToggleModal(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Appointments loading={loading} appointments={appointments} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={isRestrictedAccessModalOpen}
        onClose={() => setIsRestrictedAccessModalOpen(false)}
      >
        <DialogContent>
          You do not currently have access to this feature. Please speak to your
          property staff with any questions.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsRestrictedAccessModalOpen(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  subheader: {
    fontSize: theme.typography.pxToRem(20),
  },
  serviceLink: {
    textDecoration: 'none',
  },
  h5: {
    fontWeight: 500,
    fontSize: 17,
  },
  serviceSubheader: {
    textTransform: 'capitalize',
  },
  bookingsButton: {
    borderRadius: 10,
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: colors.livly(0.1),
  },
}));

interface OwnProps {
  user: User | null;
  services: AcuityService[];
  appointments: AcuityAppointment[];
  loading: boolean;
  error: string;
  token: string;
  onStartBooking: (service: AcuityService) => void;
}

type Props = OwnProps & RouteComponentProps;

export default Services;
